export const accessGroup = {
  commercial: [
    'ggg_commercial_central_colleagues',
    'man-mymorri-commercialhomepage-ug',
  ],
  retailComms: [
    'ggg_store_colleagues',
    'ggg_central_colleagues'
  ],
  convNews: [
    'ggg_convenience_store_managers',
    'ggg_convenience_colleagues',
    'ggg_convenience_central_colleagues',
    'ggg_convenience_area_manager_colleagues'
  ]
}