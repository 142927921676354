import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { useSiteState } from 'store/sites-context'
import { useSitesState } from 'store/sites-context'
import { useUserState } from 'store/user-context'
import { isInGroup } from 'components/portal/access'

import ReactGA from 'react-ga4'

const HeadTabItem = ({ text, active, to }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Top Bar Click',
      label: text,
    })
    navigate(to)
  }

  return (
    <div
      className={`${active &&
        'bg-secondary shadow-md border-secondary text-white'} truncate cursor-pointer bg-grey-lightest border-l border-r border-grey flex items-start justify-center px-2 md:px-6 py-1 mr-1`}
      onClick={handleClick}
    >
      <span className="font-medium leading-tight truncate sm:leading-normal text-2xs">
        {text}
      </span>
    </div>
  )
}

const HeaderTab = ({ activeTab }) => {
  const { getSites } = useSitesState()
  const userState = useUserState()
  const currentUser = userState.current_user
  const siteList = getSites()

  return (
    <div className="w-full bg-gradient-grey-top-tab">
      <div className="container flex items-stretch px-1 py-0 mx-0 my-0 md:mx-auto md:px-8 text-secondary">
        <HeadTabItem text={'MyMorri'} active={activeTab === 'mymorri'} to="/" />
        {siteList &&
          siteList.map((site, key) => {
            if (
              site.href.slug === 'retail-comms' &&
              !isInGroup(currentUser.groups, 'retailComms')
            ) {
              return null
            }

            if (
              site.href.slug === 'conv-news' &&
              !isInGroup(currentUser.groups, 'convNews')
            ) {
              return null
            }

            // Removing Coronavirus site top tab in config for now
            // Looking to remove this API call at a later date
            if (site.href.slug === 'covid-19') {
              return null
            }
            return (
              <HeadTabItem
                key={key}
                text={site.title}
                active={activeTab === site.href.slug}
                to={`/sites/${site.href.slug}`}
              />
            )
          })}
        {/* {isInGroup(currentUser.groups) && (
          <HeadTabItem
            text={'Commercial Homepage'}
            active={activeTab === 'commercialhomepage'}
            to="/portal/commercialhomepage"
          />
        )} */}
      </div>
    </div>
  )
}

export default HeaderTab
