import React from 'react'
import { useNavigate } from 'react-router-dom'
import config from 'config'
import storesList from 'data/stores.json'
import { addToAnalytics } from 'store/news-context'
import Tile from './Tile'
import { useIsMobile } from '../../util'
import { useNewsState } from 'store/news-context'
import { useUserState } from 'store/user-context'
// import ActivityPlanner from 'assets/icons/svg/activity-planner.svg'
import ColleagueFacebook from 'assets/icons/svg/colleague-facebook.svg'
import DataProtection from 'assets/icons/svg/data-protection.svg'
import Email from 'assets/icons/svg/email.svg'
import ThinkShrink from 'assets/icons/svg/think-shrink.svg'
// import Leavers from 'assets/icons/svg/leavers.svg'
import MyCommunities from 'assets/icons/svg/my-communities.svg'
import MyHandbook from 'assets/icons/svg/my-handbook.svg'
import MyLearningDevelopment from 'assets/icons/svg/my-learning-development.svg'
import MyPay from 'assets/icons/svg/my-pay.svg'
import PayslipArchive from 'assets/icons/svg/payslip-archive.svg'
import MyPerformance from 'assets/icons/svg/my-performance.svg'
import MyPerks from 'assets/icons/svg/my-perks.svg'
import MyWellbeing from 'assets/icons/svg/my-wellbeing.svg'
import MySchedule from 'assets/icons/svg/my-schedule.svg'
import PeopleSystemGuide from 'assets/icons/svg/people-system-guide.svg'
import PeopleSystem from 'assets/icons/svg/people-system.svg'
import MyPersonalInfo from 'assets/icons/svg/my-personal-info.svg'
import MyBankDetails from 'assets/icons/svg/my-bank-details.svg'
import MyTeam from 'assets/icons/svg/my-team.svg'
import MyTeamSchedule from 'assets/icons/svg/my-team-schedule.svg'
import MyExpenses from 'assets/icons/svg/my-expenses.svg'
import OurSafeLegal from 'assets/icons/svg/our-safe-legal.svg'
import Toolkit from 'assets/icons/svg/my-toolkit.svg'
import WorkingPattern from 'assets/icons/svg/new-my-working-pattern-01.svg'
import HelloHillMore from 'assets/icons/svg/my-working-pattern.svg'
import YourSayDashboard from 'assets/icons/svg/your-say-dashboard.svg'
import Discount from 'assets/icons/svg/discount.svg'
import WelcomeToMorrisons from 'assets/icons/svg/welcome-to-morrisons.svg'
// import YourSaySurvey from 'assets/icons/svg/your-say-survey-colleagues.svg'
import MyTech from 'assets/icons/svg/my-tech.svg'
import MyCareer from 'assets/icons/svg/my-career.svg'
import Diamond from 'assets/icons/svg/diamond.svg'
import PeopleManagerHub from 'assets/icons/svg/people-manager-hub.svg'
import MySecurity from 'assets/icons/svg/my-security.svg'
import GoogleHub from 'assets/icons/svg/google-hub.svg'
import TechHub from 'assets/icons/svg/tech-hub.svg'
// import DiscountFamilyFriends from 'assets/icons/svg/discount-family-friends.svg'
import MyDocuments from 'assets/icons/svg/my-documents.svg'
import HowDoI from 'assets/icons/svg/how-do-i.svg'
import InternalVacancies from 'assets/icons/svg/internal-vacancies.svg'
import BusinessContinuity from 'assets/icons/svg/business-continuity-01.svg'
import RangeChange from 'assets/icons/svg/range-change-v03.svg'
import ControlTower from 'assets/icons/svg/control-tower-01.svg'
import MyConvenienceStore from 'assets/icons/svg/my-convenience-store.svg'
import MyMaintenance from 'assets/icons/svg/my-maintanance-v3.svg'
import PetrolWebclock from 'assets/icons/svg/petrol-webclock.svg'
import MyLogisticToolkit from 'assets/icons/svg/my-logistic-toolkit-04.svg'
import RetailWheel from 'assets/icons/svg/retail-wheel-5.svg'
import RecommendtoRami from 'assets/icons/svg/rami-06.svg'
import ColleageMonth from 'assets/icons/svg/colleage-month-01.svg'
import NPS from 'assets/icons/svg/nps-05.svg'
import PeopleReporting from 'assets/icons/svg/retail-wheel-3.svg'
import Commercial from 'assets/icons/svg/commercial-homepage.svg'
import MyCustomerVoice from 'assets/icons/svg/my-customer-voice-02.svg'
import MyHelp from 'assets/icons/svg/my-help-04.svg'

const MyServices = props => {
  const [isMobile] = useIsMobile()
  const newsState = useNewsState()
  const userState = useUserState()
  const navigate = useNavigate()

  const onClick = (e, paramsObj, on_click) => {
    addToAnalytics(paramsObj.text)
    if (on_click) on_click(e, paramsObj.url)
  }

  // const onEPayslipsClick = (e, url) => {
  //   if (newsState.notifications.payslip?.count !== 0) {
  //     e.preventDefault()
  //     // triggerNotificationClick('payslip', true)
  //   }
  // }

  const onMyRotaClick = (e, url) => {
    if (isMobile) {
      navigate('/myrota')
      e.preventDefault()
    }
  }

  const getPerformLink = () => {
    const { current_user } = userState
    const authenticatedUser = `?authuser=${current_user.email}`
    const link = current_user?.groups?.includes(config.salaried_group)
      ? config.performSalaried + authenticatedUser
      : current_user?.groups?.includes(config.hourly_group)
        ? config.performHourly + authenticatedUser
        : config.performGeneric + authenticatedUser
    return link
  }

  const getHandbookLink = () => {
    const { current_user } = userState
    const group = current_user?.groups?.filter(groupx =>
      config.handbook.find(handbook => handbook.group === groupx)
    )
    const handbookLink = config.handbook.find(item => item.group === group[0])
    if (handbookLink && handbookLink.link) return handbookLink.link
    return null
  }

  const getWorkingPatternLink = () => {
    const { current_user } = userState
    const group = current_user?.groups?.filter(groupx =>
      config.workingPattern.find(
        workingPattern => workingPattern.group === groupx
      )
    )
    const workingPatternLink = config.workingPattern.find(
      item => item.group === group[0]
    )
    if (workingPatternLink && workingPatternLink.link)
      return workingPatternLink.link
    return null
  }

  const getReflexisLink = () => config.reflexisLink

  const getCommunitiesLink = () => config.communitiesLink

  const getDataProtectionLink = () => config.dataProtectionLink

  const getColleagueDiscountRegistrationLink = () =>
    config.colleagueDiscountRegistrationLink
  const getColleagueDiscountManagementLink = () =>
    config.colleagueDiscountManagementLink
  const getMyDocumentsLink = () => config.myDocumentsLink

  const getEmpcenterMyScheduleLink = () => config.empcenterMyScheduleLink

  const getDiamondsFormLink = () => config.diamondsFormLink

  // const getYourSaySurveyLink = () => config.yourSaySurveyLink

  const getPetrolWebclockUrl = () => {
    const { current_user } = userState
    if (current_user && current_user.store_id) {
      return `https://knlmorrisons.reflexisinc.co.uk/RWS4/WebClockLogin.jsp?UnitID=${
        current_user.store_id
      }`
    }
    return 'https://knlmorrisons.reflexisinc.co.uk/RWS4/WebClockLogin.jsp?UnitID='
  }

  const getIcons = mediaClass => {
    const { current_user } = userState

    const isSalariedColleague =
      current_user && current_user?.groups?.includes(config.salaried_group)

    const isStoreColleague =
      current_user &&
      current_user.store_id &&
      (current_user?.groups?.includes(config.store_group) ||
        storesList.stores.includes(current_user.store_id))

    const hasThinkShrinkAccess = current_user?.groups?.includes(
      config.thinkShrink_group
    )

    const performLink = getPerformLink()
    const handbookLink = getHandbookLink()
    const workingPatternLink = getWorkingPatternLink()
    const reflexisLink = getReflexisLink()
    const communitiesLink = getCommunitiesLink()
    const dataProtectionLink = getDataProtectionLink()

    const groupToEmail = group => group + '@' + config.email_domain

    // eslint-disable-next-line
    const isInGroup = groups =>
      groups.some(r => current_user?.groups?.indexOf(groupToEmail(r)) >= 0)

    let icons = []

    // const epaylip_notification =
    //   newsState.notifications.payslip &&
    //   newsState.notifications.payslip?.count !== 0 ? (
    //     <div className="epayslip_count notification">
    //       {newsState.notifications.payslip?.count}
    //     </div>
    //   ) : null

    //Hide all regular tiles for Convenience Colleagues (Convenience Colleagues tiles are at bottom of page)
    if (
      !isInGroup([
        'ggg_convenience_store_manager_colleagues',
        'ggg_convenience_colleagues',
        'ggg_convenience_detroit_colleagues',
        'ggg_convenience_child_worker_colleagues',
        'convenience.leavers',
      ])
    ) {
      // Regular Tiles Below
      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'diamonds_form',
            groupId: '3',
            svg: Diamond,
            newFromDate: '16/01/2024',
            text: 'Diamonds',
            key: 'diamond-product-form',
            url:
              'https://docs.google.com/forms/d/e/1FAIpQLSfTUOEv0oQvYd2EyKsPvpy4frfXVzNb_0Pwe7hsxbGzAqw3oA/viewform',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'recommend_to_rami',
            groupId: '3',
            svg: RecommendtoRami,
            newFromDate: '02/22/2024',
            text: 'Recommend to Rami',
            key: 'recommend-to-rami',
            url:
              'https://docs.google.com/forms/d/e/1FAIpQLSc9qetG_6WKJ7AF6fB8rxEvDbYmXvyD1VJBSdtHmlujjYCszA/viewform',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['your-nps-survey-ug'])) {
        icons = [
          ...icons,
          {
            id: 'nps',
            groupId: '3',
            svg: NPS,
            newFromDate: '04/18/2024',
            text: 'NPS',
            key: 'nps',
            url:
              'https://docs.google.com/presentation/d/1TUG3GSzBWdY93OI2d0bAlpgLs8ZECjbP1Epphd35qgM/edit?usp=sharing',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'colleague_of_the_month',
            groupId: '3',
            svg: ColleageMonth,
            newFromDate: '04/18/2024',
            text: 'MorriStars',
            key: 'colleague-of-the-month',
            url:
              'https://docs.google.com/forms/d/e/1FAIpQLSd4crhELeefpS7hY1p8ixsRE6e1dXfDwppVu93dUNFKkR4w7w/viewform?usp=sf_link',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'my_customer_voice',
            groupId: '3',
            svg: MyCustomerVoice,
            newFromDate: '04/20/2024',
            text: 'My Customer Voice',
            key: 'my-customer-voice',
            url: '',
            menu: [
              {
                text: 'Mystery Shop',
                url:
                  'https://docs.google.com/forms/d/e/1FAIpQLScrEn-7kmCz6nv7I4jOwFhtYdJEhlii9enHfZnus2UJCDy5LA/viewform?usp=sf_link',
              },
              {
                text: 'Customer Verbatim Dashboard',
                url:
                  'https://lookerstudio.google.com/reporting/18161e2d-c1de-4041-b434-1c805673b10b/page/PqfiD',
              },
            ],
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (current_user.groups.indexOf(config.payslips_group) !== -1) {
        if (
          (!current_user.groups.indexOf(config.leaver_group) &&
            isInGroup(['ggg_452_colleagues'])) ||
          (current_user.groups.indexOf(config.leaver_group) &&
            current_user.store_id === '452')
        ) {
          icons = [
            ...icons,
            {
              id: 'my_pay',
              groupId: '2',
              svg: MyPay,
              text: 'My Payslip',
              key: 'My Pay',
              url: config.epayslips_url,
              iconClass: `app-icon ${mediaClass}`,
              // notification: epaylip_notification,
              // on_click: (e, url) => onEPayslipsClick(e, url),
              openInNewWindow: true,
              handleClick: onClick,
            },
          ]
        }

        if (
          (!current_user.groups.indexOf(config.leaver_group) &&
            !isInGroup(['ggg_452_colleagues'])) ||
          (current_user.groups.indexOf(config.leaver_group) &&
            current_user.store_id !== '452')
        ) {
          icons = [
            ...icons,
            {
              id: 'my_pay',
              groupId: '2',
              svg: MyPay,
              text: 'My Payslip',
              key: 'My Pay',
              url:
                'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=VIEW_RES_PAY_SLIP',
              openInNewWindow: true,
              iconClass: `app-icon ${mediaClass}`,
              openInNewWindow: true,
              handleClick: onClick,
            },
          ]
          icons = [
            ...icons,
            {
              id: 'playslip_archive',
              groupId: '2',
              svg: PayslipArchive,
              text: 'Payslip Archive',
              key: 'My Pay Archive',
              url: config.epayslips_url,
              iconClass: `app-icon ${mediaClass}`,
              // notification: epaylip_notification,
              // on_click: (e, url) => onEPayslipsClick(e, url),
              openInNewWindow: true,
              handleClick: onClick,
            },
          ]
        }
      }

      if (isInGroup(['ggg_452_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'my_handbook',
            groupId: '1',
            svg: MyHandbook,
            text: 'My Handbook',
            key: 'My Handbook',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/myhandbook-retail/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (!isInGroup(['ggg_452_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'my_handbook',
            groupId: '1',
            svg: MyHandbook,
            newFromDate: isInGroup(['morrisons.leavers'])
              ? '04/14/2023'
              : '04/14/2022',
            text: 'My Handbook',
            key: 'My Handbook',
            url: isInGroup(['ggg_store_colleagues'])
              ? config.handbookLinkRetailNPS
              : handbookLink,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_store_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'toolkit',
            groupId: '0',
            svg: Toolkit,
            newFromDate: '02/01/2022',
            text: 'My Store Toolkit',
            key: 'Toolkit',
            url: 'https://sites.google.com/morrisonsplc.co.uk/my-store-toolkit',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (!isInGroup(['morrisons.leavers'])) {
        icons = [
          ...icons,
          {
            id: 'communities',
            groupId: '1',
            svg: MyCommunities,
            text: 'My Spaces',
            key: 'MySpaces',
            url: communitiesLink,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'fb',
            groupId: '1',
            svg: ColleagueFacebook,
            text: 'MorriChat',
            key: ' MorriChat',
            newFromDate: '11/21/2023',
            url: 'https://www.facebook.com/groups/717780776891291',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'my_learning_development',
            groupId: '0',
            svg: MyLearningDevelopment,
            newFromDate: '07/11/2021',
            text: 'My Learning & Development',
            key: 'My Learning & Development',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/mylearningdevelopment/',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'my_career',
            groupId: '0',
            svg: MyCareer,
            newFromDate: '07/11/2021',
            text: 'My Career',
            key: 'MyCareer',
            url: 'https://sites.google.com/morrisonsplc.co.uk/my-career/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_line_managers_central_colleagues',
          'ggg_line_managers_logistics_colleagues',
          'ggg_line_managers_manufacturing_colleagues',
          'ggg_line_managers_stores_colleagues',
          'ggg_people_assistants_store_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my-recruitment',
            groupId: '0',
            svg: InternalVacancies,
            newFromDate: '03/02/2022',
            text: 'My Recruitment',
            key: 'MyRecruitment',
            url: '',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            menu: [
              {
                text: 'Internal Vacancies',
                url: 'https://www.morrisons.jobs/internal-vacancies',
              },
              {
                text: 'Hiring Manager Portal',
                url: 'https://morrisonshm.eploy.net/clientarea/login.aspx',
              },
            ],
          },
        ]
      } else if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'internal_vacancies',
            groupId: '0',
            svg: InternalVacancies,
            newFromDate: '03/02/2022',
            text: 'Internal Vacancies',
            key: 'InternalVacancies',
            url: 'https://www.morrisons.jobs/internal-vacancies',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (!isInGroup(['morrisons.leavers'])) {
        icons = [
          ...icons,
          {
            id: 'perform',
            groupId: '0',
            svg: MyPerformance,
            text: 'My Performance',
            key: 'Perform',
            url: performLink,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'gmail',
            groupId: '0',
            svg: Email,
            text: 'Email',
            key: 'Email',
            url: 'https://mail.google.com',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'my_perks',
            groupId: '2',
            svg: MyPerks,
            text: 'My Benefits',
            key: 'My Benefits',
            url: 'https://sites.google.com/morrisonsplc.co.uk/mybenefits/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'wellbeing',
            groupId: '1',
            svg: MyWellbeing,
            text: 'My Wellbeing',
            key: 'Wellbeing',
            url: 'https://sites.google.com/morrisonsplc.co.uk/mywellbeing/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
          {
            id: 'dataprotection',
            groupId: '1',
            svg: DataProtection,
            text: 'Data Protection',
            key: 'DataProtection',
            url: dataProtectionLink,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'my_personal_info',
            groupId: '1',
            svg: MyPersonalInfo,
            text: 'My Personal Info',
            key: 'MyPersonalInfo',
            url:
              'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=FUSE_PER_INFO',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
        icons = [
          ...icons,
          {
            id: 'my_bank_details',
            groupId: '1',
            svg: MyBankDetails,
            text: 'My Bank Details',
            key: 'MyBankDetails',
            url:
              'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=VIEW_RES_PAY_METHOD',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      /* People System Guide */
      if (
        isInGroup([
          'man-people-colleagues-ug',
          'man-nps-people-managers-ug',
          'ggg_people_team_distribution_colleagues',
          'ggg_people_team_manufacturing_colleagues',
          'ggg_people_team_store_colleagues',
          'mymorri-people-team-central-colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'people_system_guide_people_team',
            groupId: '1',
            svg: PeopleSystemGuide,
            text: 'People System Guide',
            key: 'PeopleSystemGuide',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/people-system-guides/',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }
      if (
        isInGroup([
          'ggg_line_managers_stores_colleagues',
          'ggg_line_managers_logistics_colleagues',
          'ggg_line_managers_manufacturing_colleagues',
          'ggg_line_managers_central_colleagues',
          'man-line-manager-colleagues-ug',
          'man-nps-line-managers-ug',
        ]) &&
        !isInGroup([
          'man-people-colleagues-ug',
          'man-nps-people-managers-ug',
          'ggg_people_team_distribution_colleagues',
          'ggg_people_team_manufacturing_colleagues',
          'ggg_people_team_store_colleagues',
          'mymorri-people-team-central-colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'people_system_guide_managers',
            groupId: '1',
            svg: PeopleSystemGuide,
            text: 'People System Guide',
            key: 'PeopleSystemGuide',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/people-system-line-managers/',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }
      if (
        isInGroup(['ggg_all_colleagues']) &&
        !isInGroup([
          'man-people-colleagues-ug',
          'man-line-manager-colleagues-ug',
          'man-nps-people-managers-ug',
          'man-nps-line-managers-ug',
          'ggg_line_managers_central_colleagues',
          'ggg_line_managers_stores_colleagues',
          'ggg_line_managers_logistics_colleagues',
          'ggg_line_managers_manufacturing_colleagues',
          'ggg_people_team_distribution_colleagues',
          'ggg_people_team_manufacturing_colleagues',
          'ggg_people_team_store_colleagues',
          'mymorri-people-team-central-colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'people_system_guide_central',
            groupId: '1',
            svg: PeopleSystemGuide,
            text: 'People System Guide',
            key: 'PeopleSystemGuide',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/people-system-colleague/',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }
      /* End People System Guide */

      // My Store Toolkit original placement

      if (
        isInGroup([
          'ggg_people_team_distribution_colleagues',
          'ggg_people_team_manufacturing_colleagues',
          'ggg_people_team_store_colleagues',
          'mymorri-people-team-central-colleagues',
          'man-people-colleagues-ug',
          'ggg_early_careers_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'people_system',
            groupId: '0',
            svg: PeopleSystem,
            text: 'People System',
            key: 'PeopleSystem',
            url: 'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/FuseWelcome',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup(['ggg_central_colleagues']) &&
        !isInGroup(['ggg_facilities_central_colleagues'])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_schedule_hcm',
            groupId: '0',
            svg: MySchedule,
            text: 'My Schedule',
            key: 'MySchedule',
            url:
              'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=ABSENCE_BALANCE',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_line_managers_stores_colleagues',
          'ggg_line_managers_logistics_colleagues',
          'ggg_line_managers_manufacturing_colleagues',
          'ggg_line_managers_central_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_team_hcm',
            groupId: '0',
            svg: MyTeam,
            text: 'My Team',
            key: 'MyTeam',
            url:
              'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=MY_TEAM',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_line_managers_stores_colleagues',
          'ggg_regional_people_managers_store_colleagues',
          'ggg_regional_managers_store_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_team_schedule_reflexis',
            groupId: '0',
            svg: MyTeamSchedule,
            newFromDate: '07/28/2020',
            text: 'My Team Schedule',
            key: 'MyTeamSchedule',
            url: 'https://morrisons.reflexisinc.co.uk/RWS4/',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_manufacturing_colleagues',
          'ggg_distribution_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_schedule_empcenter',
            groupId: '0',
            svg: MySchedule,
            newFromDate: '02/09/2022',
            text: 'My Schedule',
            key: 'MySchedule',
            url: getEmpcenterMyScheduleLink(),
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_facilities_central_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'reflexis',
            groupId: '0',
            svg: MySchedule,
            text: 'My Schedule',
            key: 'ReflexisRotas',
            url: reflexisLink,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            on_click: (e, url) => onMyRotaClick(e, url),
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_store_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'myschedulestore',
            groupId: '0',
            svg: MySchedule,
            text: 'My Schedule',
            key: 'MyScheduleStore',
            url: reflexisLink,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            menu: [
              {
                text: 'My Schedule',
                url: reflexisLink,
                on_click: (e, url) => onMyRotaClick(e, url),
              },
              {
                text: 'FAQs',
                url:
                  'https://docs.google.com/document/d/1l_BDBofHQhHfTsVZdCwwjr7CHkeDaj0yIMb8N6OlRt0/edit',
              },
            ],
          },
        ]
      }

      if ((isSalariedColleague && isStoreColleague) || hasThinkShrinkAccess) {
        icons = [
          ...icons,
          {
            id: 'think_shrink',
            groupId: '0',
            svg: ThinkShrink,
            text: 'Think Shrink',
            key: 'ThinkShrink',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/losspreventionhome/home-page',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'safe_legal',
            groupId: '0',
            svg: OurSafeLegal,
            newFromDate: '01-01-2020',
            text: 'Our Safe & Legal',
            key: 'OurSafeLegal',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/our-safe-and-legal',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'my_expenses',
            groupId: '2',
            svg: MyExpenses,
            text: 'My Expenses',
            key: 'MyExpenses',
            url: 'https://sites.google.com/morrisonsplc.co.uk/my-expenses/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }
      if (
        isInGroup([
          'ggg_technology_central_colleagues',
          'man-myworkingpattern-centrallogistics-ug',
          'man-my-working-pattern---central-manufacturing-ug',
          'ggg_retail_central_colleagues',
          'ggg_loss_prevention_central_colleagues',
          'ggg_commercial_central_colleagues',
          'ggg_marketing_central_colleagues',
          'man-my-working-pattern-contact-centre-ug',
          'ggg_corporate_services_central_colleagues',
          'man-my-working-pattern-directorate-ug',
          'ggg_finance_central_colleagues',
          'ggg_online_central_colleagues',
          'ggg_procurement_central_colleagues',
          'ggg_productivity_operations_central_colleagues',
          'ggg_supply_chain_central_colleagues',
          'ggg_property_central_colleagues',
          'man-my-working-pattern-services-ug',
          'ggg_shared_services_central_colleagues',
          'ggg_wholesale_central_colleagues',
          'man-my-working-pattern---coalville-nutmeg-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_working_pattern',
            groupId: '0',
            svg: WorkingPattern,
            newFromDate: '07/28/2020',
            text: 'My Working Pattern',
            key: 'My Working Pattern',
            url: workingPatternLink,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'man-yoursaydashboard-pilot-ug',
          // 'ggg_line_managers_logistics_colleagues',
          // 'ggg_line_managers_stores_colleagues',
          // 'ggg_line_managers_manufacturing_colleagues',
          // 'ggg_line_managers_central_colleagues',
          // 'ggg_people_managers_store_colleagues',
          // 'hk-managers-ug',
          // 'peru-managers-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'your_say_dashboard',
            groupId: '0',
            svg: YourSayDashboard,
            newFromDate: '06/28/2022',
            text: 'Your Say Dashboard',
            key: 'YourSayDashboard',
            url: config.yourSayDashboard,
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      // Commenting out Your Say Survey tile to keep for next year
      // if (
      //   isInGroup([
      //     'ggg_all_colleagues',
      //     'hk-colleagues-ug',
      //     'peru-colleagues-ug',
      //   ])
      // ) {
      //   icons = [
      //     ...icons,
      //     {
      //       id: 'your_say_survey',
      //       groupId: '0',
      //       svg: YourSaySurvey,
      //       newFromDate: '06/28/2022',
      //       text: 'Your Say Survey',
      //       key: 'YourSaySurvey',
      //       url: getYourSaySurveyLink(),
      //       iconClass: `app-icon ${mediaClass}`,
      //       openInNewWindow: true,
      //       handleClick: onClick,
      //     },
      //   ]
      // }

      if (!isInGroup(['morrisons.leavers'])) {
        icons = [
          ...icons,
          {
            id: 'my_tech',
            groupId: '0',
            svg: MyTech,
            newFromDate: '05-05-2021',
            text: 'My Tech',
            key: 'MyTech',
            url: 'https://morrisons.service-now.com/sp',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            menu: [
              {
                text: 'My Tech Portal',
                url: 'https://morrisons.service-now.com/sp',
              },
              {
                text: 'Tech Leadership Escalations',
                url: 'https://forms.gle/y5wEReJUgcDz2Mc9A',
              },
            ],
          },
        ]
      }

      if (
        isInGroup([
          'man-mymorri-colleaguediscount-pilot-ug',
          'ggg_all_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'discount',
            groupId: '2',
            svg: Discount,
            newFromDate: '10/29/2020',
            text: 'Colleague Discount',
            key: 'Discount',
            url: getColleagueDiscountRegistrationLink(),
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            menu: [
              {
                text: 'Colleague and Family & Friends Discount',
                url: getColleagueDiscountManagementLink(),
              },
              {
                text: 'Online discount registration',
                url: getColleagueDiscountRegistrationLink(),
              },
              {
                text: 'Questions and Answers',
                url:
                  'https://docs.google.com/document/d/18ep7vlkn7JzU45fUJILHnjk7tAb-8U8aJZm81rks650/edit?usp=sharing',
              },
            ],
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'welcome_to_morrisons',
            groupId: '1',
            svg: WelcomeToMorrisons,
            newFromDate: '04/06/2021',
            text: "Everyone's Welcome at Morrisons",
            key: 'WelcomeToMorrisons',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/everyoneswelcomeandcelebrated',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_hilmore_central_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'hello-hilmore',
            groupId: '1',
            svg: HelloHillMore,
            newFromDate: '02/09/2023',
            text: 'Hello Hilmore',
            key: 'HelloHilmore',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/hellohilmore/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_store_worklevel3_colleagues',
          'ggg_store_worklevel5_colleagues',
          'ggg_manufacturing_worklevel3_colleagues',
          'ggg_manufacturing_worklevel4_colleagues',
          'ggg_manufacturing_worklevel5_colleagues',
          'ggg_distribution_worklevel3_colleagues',
          'ggg_distribution_worklevel4_colleagues',
          'ggg_distribution_worklevel5_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_security',
            groupId: '0',
            svg: MySecurity,
            newFromDate: '07/12/2021',
            text: 'My Security',
            key: 'MySecurity',
            url: 'https://www.dymension.co.uk/#/account/login',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_people_team_store_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'people_manager_hub',
            groupId: '1',
            svg: PeopleManagerHub,
            newFromDate: '07/13/2021',
            text: 'People Manager Hub',
            key: 'PeopleManagerHub',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/people-manager-hub/talent-development',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_regional_people_managers_store_colleagues',
          'ggg_regional_admin_assistants_store_colleagues',
          'mymorri-rpm-hub-central-colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'regional_people_manager_hub',
            groupId: '1',
            svg: PeopleManagerHub,
            newFromDate: '07/13/2021',
            text: 'Regional People Manager Hub',
            key: 'RegionalPeopleManagerHub',
            url: 'https://sites.google.com/morrisonsplc.co.uk/rpmhub/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'google_hub',
            groupId: '0',
            svg: GoogleHub,
            newFromDate: '09/07/2021',
            text: 'Google Hub',
            key: 'GoogleHub',
            url:
              'https://sites.google.com/netpremacy.com/morrisons-training-hub/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_technology_central_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'tech_hub',
            groupId: '0',
            svg: TechHub,
            newFromDate: '11/01/2021',
            text: 'The Hub',
            key: 'TechHub',
            url: 'https://sites.google.com/morrisonsplc.co.uk/technology/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_hilmore_central_colleagues',
          'ggg_field_central_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'toolkit',
            groupId: '0',
            svg: Toolkit,
            newFromDate: '06/01/2020',
            text: 'My Store Toolkit',
            key: 'Toolkit',
            url: 'https://sites.google.com/morrisonsplc.co.uk/my-store-toolkit',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_all_colleagues', 'morrisons.leavers'])) {
        icons = [
          ...icons,
          {
            id: 'my_documents',
            groupId: '2',
            svg: MyDocuments,
            newFromDate: '01/02/2022',
            text: 'My Documents',
            key: 'MyDocuments',
            url: getMyDocumentsLink(),
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_commercial_hilmore_central_colleagues',
          'ggg_corporate_hilmore_central_colleagues',
          'ggg_marketing_hilmore_central_colleagues',
          'ggg_supply_chain_hilmore_central_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'how_do_i',
            groupId: '0',
            svg: HowDoI,
            newFromDate: '02/24/2022',
            text: 'How Do I',
            key: 'HowDoI',
            url: 'https://sites.google.com/morrisonsplc.co.uk/howdoi/',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_hilmore_central_colleagues',
          'ggg_store_worklevel3_colleagues',
          'ggg_store_worklevel4_colleagues',
          'ggg_store_worklevel5_colleagues',
          'ggg_manufacturing_worklevel3_colleagues',
          'ggg_manufacturing_worklevel4_colleagues',
          'ggg_manufacturing_worklevel5_colleagues',
          'ggg_distribution_worklevel3_colleagues',
          'ggg_distribution_worklevel4_colleagues',
          'ggg_distribution_worklevel5_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'business_continuity',
            groupId: '0',
            svg: BusinessContinuity,
            newFromDate: '03/02/2022',
            text: 'Business Continuity',
            key: 'BusinessContinuity',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/business-continuity/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_manufacturing_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'my_flex_shifts',
            groupId: '0',
            svg: MySchedule,
            newFromDate: '',
            text: 'My Flex Shifts',
            key: 'MyFlexShifts',
            url: 'https://pepl-myflexshifts-prd-fb.web.app',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_supply_chain_central_colleagues',
          'ggg_commercial_central_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'range_change_management',
            groupId: '0',
            svg: RangeChange,
            newFromDate: '07/31/2023',
            text: 'Range Change Management',
            key: 'RangeChangeManagement',
            url: '',
            menu: [
              {
                text: 'Range Change Management',
                url: 'https://hcwwebapp.apps.mymorri.com/',
              },
              {
                text: 'Range User Guide',
                url:
                  'https://docs.google.com/presentation/d/1Tq4lGkiPtIHcJKE0601S6JywJMo4g63ZBD8Qd8Qdeq8/edit#slide=id.g1176b9e2a3f_0_214',
              },
              {
                text: 'Range Commercial Reports',
                url: 'https://morrisonsprd.cloud.looker.com/folders/698',
              },
            ],
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (isInGroup(['ggg_distribution_colleagues'])) {
        icons = [
          ...icons,
          {
            id: 'my_logistics_toolkit',
            groupId: '0',
            svg: Toolkit,
            newFromDate: '07/21/2022',
            text: 'My Logistics Toolkit',
            key: 'MyLogisticsToolkit',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/logistics-tech-hub',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_supply_chain_central_colleagues',
          'ggg_wholesale_central_colleagues',
          'ggg_online_central_colleagues',
          'ggg_central_manufacturing_central_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'morrisons_control_tower',
            groupId: '0',
            svg: ControlTower,
            newFromDate: '07/21/2022',
            text: 'Morrisons Control Tower',
            key: 'MorrisonsControlTower',
            url: '',
            menu: [
              {
                text: 'Morrisons Control Tower',
                url: config.morrisonsControlTower.morrisonsControlTower,
              },
              {
                text: 'User Guide',
                url: config.morrisonsControlTower.userGuide,
              },
            ],
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_people_central_colleagues',
          'ggg_wholesale_central_colleagues',
          'mccolls-convenience-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_convenience',
            groupId: '0',
            svg: MyConvenienceStore,
            newFromDate: '10/19/2023',
            text: 'My Convenience',
            key: 'MyConvenience',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/morrisons-convenience?usp=sharing',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_store_worklevel3_colleagues',
          'ggg_store_worklevel4_colleagues',
          'ggg_store_worklevel5_colleagues',
          'ggg_distribution_worklevel3_colleagues',
          'ggg_distribution_worklevel4_colleagues',
          'ggg_distribution_worklevel5_colleagues',
          'hilmore-facilities-maintenance-ug',
          'ggg_shift_manager_store_colleagues',
          'retailearlycareers-ug',
          'logistics-early-careers-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'my_maintenance',
            groupId: '0',
            svg: MyMaintenance,
            newFromDate: '08/29/2023',
            text: 'My Maintenance',
            key: 'MyMaintenance',
            url: 'https://sp-fm.mercury.software',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
          },
        ]
      }

      if (
        isInGroup([
          'webclock-trial-036-colleagues-ug',
          'webclock-trial-149-colleagues-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'petrol-webclock',
            groupId: '0',
            svg: PetrolWebclock,
            newFromDate: '10/10/2023',
            text: 'Petrol Webclock',
            key: 'PetrolWebclock',
            url: getPetrolWebclockUrl(),
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            storeIdInUrl: true,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_distribution_worklevel2_colleagues',
          'ggg_distribution_worklevel3_colleagues',
          'ggg_distribution_worklevel4_colleagues',
          'ggg_distribution_worklevel5_colleagues',
          'ggg_distribution_worklevel6_colleagues',
          'ggg_distribution_worklevel7_colleagues',
          'ggg_distribution_worklevel8_colleagues',
          'ggg_distribution_worklevel9_colleagues',
          'logistics-leadership-tile-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'mylogisticstoolkit',
            groupId: '0',
            svg: MyLogisticToolkit,
            newFromDate: '11/09/2023',
            text: 'My Logistics Toolkit',
            key: 'MyLogisticsToolkit',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/mylogisticstoolkit?usp=sharing',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            storeIdInUrl: true,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_store_worklevel3_colleagues',
          'ggg_store_worklevel4_colleagues',
          'ggg_store_worklevel5_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'retail_wheel',
            groupId: '0',
            svg: RetailWheel,
            newFromDate: '02/27/2024',
            text: 'Retail Wheel',
            key: 'RetailWheel',
            url:
              'https://lookerstudio.google.com/u/0/reporting/2e31d50b-3e4f-4fe3-bdc3-a47436dee422/page/p_i4c5qqe23c',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            storeIdInUrl: true,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_commercial_central_colleagues',
          'man-mymorri-commercialhomepage-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'commercial_homepage',
            groupId: '0',
            svg: Commercial,
            newFromDate: '09/19/2024',
            text: 'Commercial Homepage',
            key: 'CommercialHomepage',
            url:
              'https://sites.google.com/morrisonsplc.co.uk/commercial-homepage/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            storeIdInUrl: true,
          },
        ]
      }

      if (
        isInGroup([
          'ggg_store_colleagues',
          'ggg_people_central_colleagues',
          'ggg_regional_managers_store_colleagues',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'hr_helpline',
            groupId: '1',
            svg: MyHelp,
            newFromDate: '03/06/2025',
            text: 'HR Helpline',
            key: 'HRHelpline',
            url: 'https://sites.google.com/morrisonsplc.co.uk/hrhelpline/home',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            storeIdInUrl: true,
          },
        ]
      }

      if (
        isInGroup([
          'absence-dashboard-access-2-ug',
          'headcount-dashboard-access-ug',
          'colleague-discount-dashboard-ug',
          'lto-dashboard-access-ug',
          'ggg_people_managers_hilmore_colleagues',
          'ggg_central_worklevel6_colleagues',
          'ggg_site_managers_manufacturing_colleagues',
          'convenience-ug',
          'store-operational-clock-dashboard-access-ug',
          'ggg_central_senior_op_manager_colleagues',
          'mnl-hourly-resourcing-dashboard-access-ug',
          'manufacturing-scorecard-access-ug',
          'ggg_people_managers_manufacturing_colleagues',
          'logistics-scorecard-access-ug',
          'ggg_people_managers_distribution_colleagues',
          'stores-scorecard-ug',
          'ggg_people_team_store_colleagues',
          'retail-management-vacancies-dashboard-access-ug',
          'ggg_regional_people_managers_store_colleagues',
          'diversity-dashboard-access-ug',
          'diversity-dashboard-access-master-ug',
          'diversity-dashboard-access-manufacturing-ug',
          'ggg_people_team_manufacturing_colleagues',
          'diversity-dashboard-access-logistics-ug',
          'ggg_people_team_distribution_colleagues',
          'nps-dashboard-access-ug',
          'er-dashboard-ug',
          'people-metrics-dashboard-access-ug',
          'ggg_people_assistants_store_colleagues',
          'diversity-dashboard-access-stores-ug',
          'ggg_people_managers_store_colleagues',
          'ggg_regional_managers_store_colleagues',
          'ggg_store_managers_store_colleagues',
          'resourcing-dashboard-access-ug',
        ])
      ) {
        icons = [
          ...icons,
          {
            id: 'people_reporting',
            groupId: '0',
            svg: PeopleReporting,
            newFromDate: '',
            text: 'People Reporting',
            key: 'peoplereporting',
            url: '',
            iconClass: `app-icon ${mediaClass}`,
            openInNewWindow: true,
            handleClick: onClick,
            menu: [
              {
                text: 'Absence',
                url:
                  'https://lookerstudio.google.com/reporting/3272617d-b7ce-476a-ad36-ee14681d7ffd',
                groups: [
                  'absence-dashboard-access-2-ug',
                  'ggg_people_managers_hilmore_colleagues',
                  'ggg_central_worklevel6_colleagues',
                  'ggg_people_managers_distribution_colleagues',
                  'ggg_people_managers_manufacturing_colleagues',
                  'ggg_people_team_distribution_colleagues',
                  'ggg_people_team_manufacturing_colleagues',
                  'ggg_people_team_store_colleagues',
                  'ggg_regional_people_managers_store_colleagues',
                  'ggg_site_managers_manufacturing_colleagues',
                ],
              },
              {
                text: 'Headcount',
                url:
                  'https://lookerstudio.google.com/reporting/a23fb111-b1cf-4df4-b22a-021109986553',
                groups: [
                  'headcount-dashboard-access-ug',
                  'ggg_people_managers_hilmore_colleagues',
                  'ggg_central_worklevel6_colleagues',
                  'ggg_people_managers_distribution_colleagues',
                  'ggg_people_managers_manufacturing_colleagues',
                  'ggg_people_team_distribution_colleagues',
                  'ggg_people_team_manufacturing_colleagues',
                  'ggg_people_team_store_colleagues',
                  'ggg_regional_people_managers_store_colleagues',
                  'ggg_site_managers_manufacturing_colleagues',
                ],
              },
              {
                text: 'Colleague Discount',
                url:
                  'https://lookerstudio.google.com/reporting/03fe2665-bf81-4fe8-86f5-4dc9a372c65c',
                groups: ['colleague-discount-dashboard-ug'],
              },
              {
                text: 'LTO',
                url:
                  'https://lookerstudio.google.com/reporting/160574d1-beb3-465c-9092-0b33e53e7b94',
                groups: [
                  'lto-dashboard-access-ug',
                  'ggg_people_managers_hilmore_colleagues',
                  'ggg_central_worklevel6_colleagues',
                  'ggg_people_managers_distribution_colleagues',
                  'ggg_people_managers_manufacturing_colleagues',
                  'ggg_people_team_distribution_colleagues',
                  'ggg_people_team_manufacturing_colleagues',
                  'ggg_people_team_store_colleagues',
                  'ggg_regional_people_managers_store_colleagues',
                  'ggg_site_managers_manufacturing_colleagues',
                ],
              },
              {
                text: 'Convenience',
                url:
                  'https://lookerstudio.google.com/reporting/bc6d9548-33de-45aa-bf29-acacda5bbbe6',
                groups: ['convenience-ug'],
              },
              {
                text: 'Operational Clock',
                url:
                  'https://lookerstudio.google.com/reporting/9c59bca2-908d-4f6f-b58a-fe0cd099fbe1',
                groups: [
                  'store-operational-clock-dashboard-access-ug',
                  'ggg_central_senior_op_manager_colleagues',
                  'ggg_regional_managers_store_colleagues',
                  'ggg_regional_people_managers_store_colleagues',
                ],
              },
              {
                text: 'M&L Hourly Resourcing',
                url:
                  'https://lookerstudio.google.com/reporting/1c1c7118-4e56-4437-8bae-ab267249f5f3',
                groups: [
                  'mnl-hourly-resourcing-dashboard-access-ug',
                  'ggg_people_team_manufacturing_colleagues',
                ],
              },
              {
                text: 'Manufacturing Scorecard',
                url:
                  'https://lookerstudio.google.com/reporting/2137cbf6-e6e1-4cef-b6d9-139f83531955',
                groups: [
                  'manufacturing-scorecard-access-ug',
                  'ggg_people_managers_manufacturing_colleagues',
                  'ggg_people_team_manufacturing_colleagues',
                ],
              },
              {
                text: 'Logistics Scorecard',
                url:
                  'https://lookerstudio.google.com/reporting/47564f76-1f62-4249-aec1-7a42c143fc87',
                groups: [
                  'logistics-scorecard-access-ug',
                  'ggg_people_managers_distribution_colleagues',
                  'ggg_people_team_distribution_colleagues',
                ],
              },
              {
                text: 'Stores Scorecard',
                url:
                  'https://lookerstudio.google.com/reporting/82b08a10-f1ed-4cbc-8300-a30981780734',
                groups: [
                  'stores-scorecard-ug',
                  'ggg_people_team_store_colleagues',
                  'ggg_regional_people_managers_store_colleagues',
                ],
              },
              {
                text: 'Retail Management Vacancies',
                url:
                  'https://lookerstudio.google.com/reporting/b0fe98b0-adb5-4c2d-9e8d-45ed6bc19715/page/p_gz9i3wg03c',
                groups: [
                  'retail-management-vacancies-dashboard-access-ug',
                  'ggg_regional_people_managers_store_colleagues',
                ],
              },
              {
                text: 'Diversity',
                url:
                  'https://lookerstudio.google.com/reporting/6f8c7fae-bcf6-495a-82f1-d81021e8bf08',
                groups: [
                  'ggg_regional_people_managers_store_colleagues',
                  'diversity-dashboard-access-ug',
                ],
              },
              {
                text: 'Diversity - Master',
                url:
                  'https://lookerstudio.google.com/reporting/964c3274-b565-423e-9764-cba442b7de1c',
                groups: ['diversity-dashboard-access-master-ug'],
              },
              {
                text: 'Diversity - Stores',
                url:
                  'https://lookerstudio.google.com/reporting/7ba48550-b666-4b54-b92a-fabf5e3b97a9',
                groups: [
                  'diversity-dashboard-access-stores-ug',
                  'ggg_people_team_store_colleagues',
                ],
              },
              {
                text: 'Diversity - Manufacturing',
                url:
                  'https://lookerstudio.google.com/reporting/c425430c-c8ba-4eec-8955-c79ca97c3745',
                groups: [
                  'diversity-dashboard-access-manufacturing-ug',
                  'ggg_people_team_manufacturing_colleagues',
                ],
              },
              {
                text: 'Diversity - Logistics',
                url:
                  'https://lookerstudio.google.com/reporting/6c6c8ec2-5aed-4e34-81a3-63344b09417b',
                groups: [
                  'diversity-dashboard-access-logistics-ug',
                  'ggg_people_team_distribution_colleagues',
                ],
              },
              {
                text: 'NPS',
                url:
                  'https://lookerstudio.google.com/reporting/16149176-2fdd-45ba-aac8-dc1af7ef0f0e',
                groups: ['nps-dashboard-access-ug'],
              },
              {
                text: 'Employee Relations',
                url:
                  'https://lookerstudio.google.com/reporting/a39363ab-8397-4270-b19d-43d6f4b05de7',
                groups: ['er-dashboard-ug'],
              },
              {
                text: 'People Metrics',
                url:
                  'https://lookerstudio.google.com/reporting/127027e8-b8c9-4f67-aa3b-fb805c6437f3',
                groups: [
                  'people-metrics-dashboard-access-ug',
                  'ggg_people_assistants_store_colleagues',
                  'ggg_people_managers_store_colleagues',
                  'ggg_regional_managers_store_colleagues',
                  'ggg_regional_people_managers_store_colleagues',
                  'ggg_store_managers_store_colleagues',
                ],
              },
              {
                text: 'Resourcing',
                url:
                  'https://lookerstudio.google.com/reporting/b863ea51-983e-461c-a468-8f3a1ed056a6',
                groups: [
                  'resourcing-dashboard-access-ug',
                  'ggg_regional_people_managers_store_colleagues',
                ],
              },
            ],
          },
        ]
      }
    }

    //Show tiles for Convenience Colleagues Below
    if (
      isInGroup(['ggg_convenience_colleagues']) &&
      !isInGroup(['ggg_convenience_child_worker_colleagues']) &&
      !isInGroup(['convenience.leavers'])
    ) {
      icons = [
        ...icons,
        {
          id: 'gmail',
          groupId: '0',
          svg: Email,
          text: 'Email',
          key: 'Email',
          url: 'https://mail.google.com',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'google_hub',
          groupId: '0',
          svg: GoogleHub,
          newFromDate: '09/07/2021',
          text: 'Google Hub',
          key: 'GoogleHub',
          url:
            'https://sites.google.com/netpremacy.com/morrisons-training-hub/home',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
      ]
    }

    if (
      isInGroup(['ggg_convenience_colleagues']) &&
      !isInGroup(['ggg_convenience_child_worker_colleagues']) &&
      !isInGroup(['convenience.leavers'])
    ) {
      icons = [
        ...icons,
        {
          id: 'my_convenience',
          groupId: '0',
          svg: MyConvenienceStore,
          newFromDate: '10/19/2023',
          text: 'My Convenience',
          key: 'MyConvenience',
          url:
            'https://sites.google.com/morrisonsplc.co.uk/morrisons-convenience?usp=sharing',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_handbook',
          groupId: '1',
          svg: MyHandbook,
          text: 'My Handbook',
          key: 'My Handbook',
          url:
            'https://sites.google.com/morrisonsplc.co.uk/myhandbook-convenience-stores/home',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'wellbeing',
          groupId: '1',
          svg: MyWellbeing,
          text: 'My Wellbeing',
          key: 'Wellbeing',
          url: 'https://sites.google.com/morrisonsplc.co.uk/mywellbeing/home',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'welcome_to_morrisons',
          groupId: '1',
          svg: WelcomeToMorrisons,
          newFromDate: '04/06/2021',
          text: "Everyone's Welcome at Morrisons",
          key: 'WelcomeToMorrisons',
          url:
            'https://sites.google.com/morrisonsplc.co.uk/everyoneswelcomeandcelebrated',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'communities',
          groupId: '1',
          svg: MyCommunities,
          text: 'My Spaces',
          key: 'MySpaces',
          url: communitiesLink,
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'fb',
          groupId: '1',
          svg: ColleagueFacebook,
          text: 'MorriChat',
          key: ' MorriChat',
          newFromDate: '11/21/2023',
          url: 'https://www.facebook.com/groups/1240985813184759',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_pay',
          groupId: '2',
          svg: MyPay,
          text: 'My Payslip',
          key: 'My Pay',
          url:
            'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=VIEW_RES_PAY_SLIP',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_perks',
          groupId: '2',
          svg: MyPerks,
          text: 'My Benefits',
          key: 'My Benefits',
          url: 'https://morrisons.rewardgateway.co.uk/SmartHub',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_documents',
          groupId: '2',
          svg: MyDocuments,
          newFromDate: '01/02/2022',
          text: 'My Documents',
          key: 'MyDocuments',
          url: getMyDocumentsLink(),
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
      ]
    }
    if (
      isInGroup(['ggg_convenience_child_worker_colleagues']) &&
      !isInGroup(['convenience.leavers'])
    ) {
      icons = [
        ...icons,
        {
          id: 'wellbeing',
          groupId: '1',
          svg: MyWellbeing,
          text: 'My Wellbeing',
          key: 'Wellbeing',
          url: 'https://sites.google.com/morrisonsplc.co.uk/mywellbeing/home',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'welcome_to_morrisons',
          groupId: '1',
          svg: WelcomeToMorrisons,
          newFromDate: '04/06/2021',
          text: "Everyone's Welcome at Morrisons",
          key: 'WelcomeToMorrisons',
          url:
            'https://sites.google.com/morrisonsplc.co.uk/everyoneswelcomeandcelebrated',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_pay',
          groupId: '2',
          svg: MyPay,
          text: 'My Payslip',
          key: 'My Pay',
          url:
            'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=VIEW_RES_PAY_SLIP',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_perks',
          groupId: '2',
          svg: MyPerks,
          text: 'My Benefits',
          key: 'My Benefits',
          url: 'https://morrisons.rewardgateway.co.uk/SmartHub',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_documents',
          groupId: '2',
          svg: MyDocuments,
          newFromDate: '01/02/2022',
          text: 'My Documents',
          key: 'MyDocuments',
          url: getMyDocumentsLink(),
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
      ]
    }
    if (isInGroup(['convenience.leavers'])) {
      icons = [
        ...icons,
        {
          id: 'my_pay',
          groupId: '2',
          svg: MyPay,
          text: 'My Payslip',
          key: 'My Pay',
          url:
            'https://eicg.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=VIEW_RES_PAY_SLIP',
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
        {
          id: 'my_documents',
          groupId: '2',
          svg: MyDocuments,
          newFromDate: '01/02/2022',
          text: 'My Documents',
          key: 'MyDocuments',
          url: getMyDocumentsLink(),
          iconClass: `app-icon ${mediaClass}`,
          openInNewWindow: true,
          handleClick: onClick,
        },
      ]
    }

    return icons
  }

  const mediaClass = isMobile ? 'media-sm' : 'media-md'
  const mys = getIcons(mediaClass)
  const filteredIcons = mys.filter(
    item => parseInt(item.groupId, 10) === props.activeIndex
  )

  return (
    <div
      data-cy="icon-group"
      className="flex flex-wrap mx-6 my-4 md:mb-8 lg:-mx-2"
    >
      {filteredIcons.map(item => (
        <Tile {...item} />
      ))}
    </div>
  )
}

export default MyServices
